import './gallery.scss';
import "lightgallery.js/dist/css/lightgallery.css";
import Loader from "react-loaders";
import PT from "prop-types";
import React from "react";
import {
    LightgalleryProvider,
    LightgalleryItem,
    useLightgallery
} from "react-lightgallery";
import Cover1 from "./../../../assets/media/bday25th/LTJ_4729.jpg";
import Cover2 from "./../../../assets/media/bday26th/LTJ_1917.jpg";
import Cover3 from "./../../../assets/media/madeFestival/IMG2811.jpg";
import Cover4 from "./../../../assets/media/bday27th/1 (97).jpg";

import { gallery1, gallery2, gallery3, gallery4 } from "../../../data/imageData";

const PhotoItem = ({ image, thumb, group }) => (
    <div style={{ maxWidth: "250px", width: "200px", padding: "5px" }}>
        <LightgalleryItem group={group} src={image} thumb={thumb}>
            <img src={image} style={{ width: "100%" }}  alt="testimage"/>
        </LightgalleryItem>
    </div>
);
PhotoItem.propTypes = {
    image: PT.string.isRequired,
    thumb: PT.string,
    group: PT.string.isRequired
};

const OpenButtonWithHook = props => {
    const { openGallery } = useLightgallery();
    return (
    <button
        {...props}
        onClick={() => openGallery("group2")}
        className={["button is-primary", props.className || ""].join(" ")}>
        <img loading="lazy" src={Cover1} alt="pic"/>
        25<sup>th</sup> Birthday
    </button>
    );
};
OpenButtonWithHook.propTypes = {
    className: PT.string
};

const OpenButtonWithHook2 = props => {
    const { openGallery } = useLightgallery();
    return (
        <button
            {...props}
            onClick={() => openGallery("group3")}
            className={["button is-primary", props.className || ""].join(" ")}>
            <img loading="lazy" src={Cover2} alt="pic"/>
            26<sup>th</sup> Birthday
        </button>
    );
};
OpenButtonWithHook2.propTypes = {
    className: PT.string
};

const OpenButtonWithHook3 = props => {
    const { openGallery } = useLightgallery();
    return (
        <button
            {...props}
            onClick={() => openGallery("group4")}
            className={["button is-primary", props.className || ""].join(" ")}>
            <img loading="lazy" src={Cover3} alt="pic"/>
            Made Festival
        </button>
    );
};
OpenButtonWithHook3.propTypes = {
    className: PT.string
};

const OpenButtonWithHook4 = props => {
    const { openGallery } = useLightgallery();
    return (
        <button
            {...props}
            onClick={() => openGallery("group5")}
            className={["button is-primary", props.className || ""].join(" ")}>
            <img loading="lazy" src={Cover4} alt="pic"/>
            27<sup>th</sup> Birthday
        </button>
    );
};
OpenButtonWithHook4.propTypes = {
    className: PT.string
};

function Gallery() {
    return (
        <div className="content">
            <div>
                    <LightgalleryProvider
                        onBeforeOpen={() => console.info("onBeforeOpen")}
                        onAfterOpen={() => console.info("onAfterOpen")}
                        onSlideItemLoad={() => console.info("onSlideItemLoad")}
                        onBeforeSlide={() => console.info("onBeforeSlide")}
                        onAfterSlide={() => console.info("onAfterSlide")}
                        onBeforePrevSlide={() => console.info("onBeforePrevSlide")}
                        onBeforeNextSlide={() => console.info("onBeforeNextSlide")}
                        onDragstart={() => console.info("onDragstart")}
                        onDragmove={() => console.info("onDragmove")}
                        onDragend={() => console.info("onDragend")}
                        onSlideClick={() => console.info("onSlideClick")}
                        onBeforeClose={() => console.info("onBeforeClose")}
                        onCloseAfter={() => console.info("onCloseAfter")}
                    >
                        <div
                            style={{
                                display: "none",
                                alignItems: "center",
                                justifyContent: "center"
                            }}>
                            {gallery1.map((p, idx) => (
                                <PhotoItem key={idx} image={p.url} group="group2" />
                            ))}
                        </div>
                        <div
                            style={{
                                display: "none",
                                alignItems: "center",
                                justifyContent: "center"
                            }}>
                            {gallery2.map((p, idx) => (
                                <PhotoItem key={idx} image={p.url} group="group3" />
                            ))}
                        </div>
                        <div
                            style={{
                                display: "none",
                                alignItems: "center",
                                justifyContent: "center"
                            }}>
                            {gallery3.map((p, idx) => (
                                <PhotoItem key={idx} image={p.url} group="group4" />
                            ))}
                        </div>
                        <div
                            style={{
                                display: "none",
                                alignItems: "center",
                                justifyContent: "center"
                            }}>
                            {gallery4.map((p, idx) => (
                                <PhotoItem key={idx} image={p.url} group="group5" />
                            ))}
                        </div>
                        <div className="container gallery-page">
                            <div className="media-container">
                                <div className="tile buttons mt-4">
                                    <OpenButtonWithHook />
                                </div>
                                <div className="tile buttons mt-4">
                                    <OpenButtonWithHook2 />
                                </div>
                                <div className="tile buttons mt-4">
                                    <OpenButtonWithHook3 />
                                </div>
                                <div className="tile buttons mt-4">
                                    <OpenButtonWithHook4 />
                                </div>
                            </div>
                        </div>
                    </LightgalleryProvider>
            </div>
            <Loader type="line-scale-pulse-out" active/>
        </div>
    );
}

export default Gallery