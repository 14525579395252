export const gallery1 = [
    {url: require('./../assets/media/bday25th/LTJ_4722.jpg')},
    {url: require('./../assets/media/bday25th/LTJ_4729.jpg')},
    {url: require('./../assets/media/bday25th/LTJ_4801.jpg')},
    {url: require('./../assets/media/bday25th/LTJ_4852.jpg')},
    {url: require('./../assets/media/bday25th/LTJ_4875.jpg')},
    {url: require('./../assets/media/bday25th/LTJ_4878.jpg')},
    {url: require('./../assets/media/bday25th/LTJ_4883.jpg')},
    {url: require('./../assets/media/bday25th/LTJ_4891.jpg')},
    {url: require('./../assets/media/bday25th/LTJ_4931.jpg')},
    {url: require('./../assets/media/bday25th/LTJ_4990.jpg')},
    {url: require('./../assets/media/bday25th/LTJ_5000.jpg')},
    {url: require('./../assets/media/bday25th/LTJ_5019.jpg')},
    {url: require('./../assets/media/bday25th/LTJ_5037.jpg')},
    {url: require('./../assets/media/bday25th/LTJ_5059.jpg')},
    {url: require('./../assets/media/bday25th/LTJ_5067.jpg')},
    {url: require('./../assets/media/bday25th/LTJ_5070.jpg')},
    {url: require('./../assets/media/bday25th/LTJ_5082.jpg')},
    {url: require('./../assets/media/bday25th/LTJ_5092.jpg')},
    {url: require('./../assets/media/bday25th/LTJ_5099.jpg')},
    {url: require('./../assets/media/bday25th/LTJ_5114.jpg')},
    {url: require('./../assets/media/bday25th/LTJ_5121.jpg')},
    {url: require('./../assets/media/bday25th/LTJ_5125.jpg')},
    {url: require('./../assets/media/bday25th/LTJ_5130.jpg')},
    {url: require('./../assets/media/bday25th/LTJ_5150.jpg')},
    {url: require('./../assets/media/bday25th/LTJ_5164.jpg')},
    {url: require('./../assets/media/bday25th/LTJ_5166.jpg')},
    {url: require('./../assets/media/bday25th/LTJ_5188.jpg')},
    {url: require('./../assets/media/bday25th/LTJ_5196.jpg')},
    {url: require('./../assets/media/bday25th/LTJ_5213.jpg')},
    {url: require('./../assets/media/bday25th/LTJ_5219.jpg')},
    {url: require('./../assets/media/bday25th/LTJ_5223.jpg')},
    {url: require('./../assets/media/bday25th/LTJ_5238.jpg')},
    {url: require('./../assets/media/bday25th/LTJ_5252.jpg')},
    {url: require('./../assets/media/bday25th/LTJ_5273.jpg')},
    {url: require('./../assets/media/bday25th/LTJ_5275.jpg')},
    {url: require('./../assets/media/bday25th/LTJ_5299.jpg')},
    {url: require('./../assets/media/bday25th/LTJ_5304.jpg')},
    {url: require('./../assets/media/bday25th/LTJ_5314.jpg')},
    {url: require('./../assets/media/bday25th/LTJ_5328.jpg')},
    {url: require('./../assets/media/bday25th/LTJ_5341.jpg')},
    {url: require('./../assets/media/bday25th/LTJ_5350.jpg')},
    {url: require('./../assets/media/bday25th/LTJ_5360.jpg')},
    {url: require('./../assets/media/bday25th/LTJ_5377.jpg')},
    {url: require('./../assets/media/bday25th/LTJ_5392.jpg')},
    {url: require('./../assets/media/bday25th/LTJ_5409.jpg')},
    {url: require('./../assets/media/bday25th/LTJ_5420.jpg')},
]

export const gallery2 = [
    {url: require('./../assets/media/bday26th/LTJ_1885.jpg')},
    {url: require('./../assets/media/bday26th/LTJ_1900.jpg')},
    {url: require('./../assets/media/bday26th/LTJ_1917.jpg')},
    {url: require('./../assets/media/bday26th/LTJ_2012.jpg')},
    {url: require('./../assets/media/bday26th/LTJ_2031.jpg')},
    {url: require('./../assets/media/bday26th/LTJ_2056.jpg')},
    {url: require('./../assets/media/bday26th/LTJ_2062.jpg')},
    {url: require('./../assets/media/bday26th/LTJ_2065.jpg')},
    {url: require('./../assets/media/bday26th/LTJ_2077.jpg')},
    {url: require('./../assets/media/bday26th/LTJ_2081.jpg')},
    {url: require('./../assets/media/bday26th/LTJ_2094.jpg')},
    {url: require('./../assets/media/bday26th/LTJ_2101.jpg')},
    {url: require('./../assets/media/bday26th/LTJ_2106.jpg')},
    {url: require('./../assets/media/bday26th/LTJ_2128.jpg')},
    {url: require('./../assets/media/bday26th/LTJ_2142.jpg')},
    {url: require('./../assets/media/bday26th/LTJ_2186.jpg')},
    {url: require('./../assets/media/bday26th/LTJ_2199.jpg')},
    {url: require('./../assets/media/bday26th/LTJ_2216.jpg')},
    {url: require('./../assets/media/bday26th/LTJ_2222.jpg')},
    {url: require('./../assets/media/bday26th/LTJ_2297.jpg')},
    {url: require('./../assets/media/bday26th/LTJ_2316.jpg')},
    {url: require('./../assets/media/bday26th/LTJ_2327.jpg')},
    {url: require('./../assets/media/bday26th/LTJ_2347.jpg')},
    {url: require('./../assets/media/bday26th/LTJ_2359.jpg')},
    {url: require('./../assets/media/bday26th/LTJ_2366.jpg')},
    {url: require('./../assets/media/bday26th/LTJ_2371.jpg')},
    {url: require('./../assets/media/bday26th/LTJ_2382.jpg')},
    {url: require('./../assets/media/bday26th/LTJ_2410.jpg')},
    {url: require('./../assets/media/bday26th/LTJ_2425.jpg')},
    {url: require('./../assets/media/bday26th/LTJ_2435.jpg')},
    {url: require('./../assets/media/bday26th/LTJ_2466.jpg')},
    {url: require('./../assets/media/bday26th/LTJ_2475.jpg')},
    {url: require('./../assets/media/bday26th/LTJ_2480.jpg')},
    {url: require('./../assets/media/bday26th/LTJ_2490.jpg')},
    {url: require('./../assets/media/bday26th/LTJ_2518.jpg')},
    {url: require('./../assets/media/bday26th/LTJ_2521.jpg')},
    {url: require('./../assets/media/bday26th/LTJ_2546.jpg')},
    {url: require('./../assets/media/bday26th/LTJ_2557.jpg')},
    {url: require('./../assets/media/bday26th/LTJ_2573.jpg')},
    {url: require('./../assets/media/bday26th/LTJ_2610.jpg')},
    {url: require('./../assets/media/bday26th/LTJ_2615.jpg')},
    {url: require('./../assets/media/bday26th/LTJ_2636.jpg')},
    {url: require('./../assets/media/bday26th/LTJ_2645.jpg')},
    {url: require('./../assets/media/bday26th/LTJ_2685.jpg')},
    {url: require('./../assets/media/bday26th/LTJ_2700.jpg')},
    {url: require('./../assets/media/bday26th/LTJ_2703.jpg')},
    {url: require('./../assets/media/bday26th/LTJ_2719.jpg')},
]

export const gallery3 = [
    {url: require('./../assets/media/madeFestival/IMG2811.jpg')},
    {url: require('./../assets/media/madeFestival/IMG_2778.jpg')},
    {url: require('./../assets/media/madeFestival/IMG_2786.jpg')},
    {url: require('./../assets/media/madeFestival/IMG_2790.jpg')},
    {url: require('./../assets/media/madeFestival/IMG_2794.jpg')},
    {url: require('./../assets/media/madeFestival/IMG_2801.jpg')},
]

export const gallery4 = [
    {url: require('./../assets/media/bday27th/1 (1).jpg')},
    {url: require('./../assets/media/bday27th/1 (2).jpg')},
    {url: require('./../assets/media/bday27th/1 (3).jpg')},
    {url: require('./../assets/media/bday27th/1 (4).jpg')},
    {url: require('./../assets/media/bday27th/1 (5).jpg')},
    {url: require('./../assets/media/bday27th/1 (6).jpg')},
    {url: require('./../assets/media/bday27th/1 (7).jpg')},
    {url: require('./../assets/media/bday27th/1 (8).jpg')},
    {url: require('./../assets/media/bday27th/1 (9).jpg')},
    {url: require('./../assets/media/bday27th/1 (10).jpg')},
    {url: require('./../assets/media/bday27th/1 (11).jpg')},
    {url: require('./../assets/media/bday27th/1 (12).jpg')},
    {url: require('./../assets/media/bday27th/1 (13).jpg')},
    {url: require('./../assets/media/bday27th/1 (14).jpg')},
    {url: require('./../assets/media/bday27th/1 (15).jpg')},
    {url: require('./../assets/media/bday27th/1 (16).jpg')},
    {url: require('./../assets/media/bday27th/1 (17).jpg')},
    {url: require('./../assets/media/bday27th/1 (18).jpg')},
    {url: require('./../assets/media/bday27th/1 (19).jpg')},
    {url: require('./../assets/media/bday27th/1 (20).jpg')},
    {url: require('./../assets/media/bday27th/1 (21).jpg')},
    {url: require('./../assets/media/bday27th/1 (22).jpg')},
    {url: require('./../assets/media/bday27th/1 (23).jpg')},
    {url: require('./../assets/media/bday27th/1 (24).jpg')},
    {url: require('./../assets/media/bday27th/1 (25).jpg')},
    {url: require('./../assets/media/bday27th/1 (26).jpg')},
    {url: require('./../assets/media/bday27th/1 (27).jpg')},
    {url: require('./../assets/media/bday27th/1 (28).jpg')},
    {url: require('./../assets/media/bday27th/1 (29).jpg')},
    {url: require('./../assets/media/bday27th/1 (30).jpg')},
    {url: require('./../assets/media/bday27th/1 (31).jpg')},
    {url: require('./../assets/media/bday27th/1 (32).jpg')},
    {url: require('./../assets/media/bday27th/1 (33).jpg')},
    {url: require('./../assets/media/bday27th/1 (34).jpg')},
    {url: require('./../assets/media/bday27th/1 (35).jpg')},
    {url: require('./../assets/media/bday27th/1 (36).jpg')},
    {url: require('./../assets/media/bday27th/1 (37).jpg')},
    {url: require('./../assets/media/bday27th/1 (38).jpg')},
    {url: require('./../assets/media/bday27th/1 (39).jpg')},
    {url: require('./../assets/media/bday27th/1 (40).jpg')},
    {url: require('./../assets/media/bday27th/1 (41).jpg')},
    {url: require('./../assets/media/bday27th/1 (42).jpg')},
    {url: require('./../assets/media/bday27th/1 (43).jpg')},
    {url: require('./../assets/media/bday27th/1 (44).jpg')},
    {url: require('./../assets/media/bday27th/1 (45).jpg')},
    {url: require('./../assets/media/bday27th/1 (46).jpg')},
    {url: require('./../assets/media/bday27th/1 (47).jpg')},
    {url: require('./../assets/media/bday27th/1 (48).jpg')},
    {url: require('./../assets/media/bday27th/1 (49).jpg')},
    {url: require('./../assets/media/bday27th/1 (50).jpg')},
    {url: require('./../assets/media/bday27th/1 (51).jpg')},
    {url: require('./../assets/media/bday27th/1 (52).jpg')},
    {url: require('./../assets/media/bday27th/1 (53).jpg')},
    {url: require('./../assets/media/bday27th/1 (54).jpg')},
    {url: require('./../assets/media/bday27th/1 (55).jpg')},
    {url: require('./../assets/media/bday27th/1 (56).jpg')},
    {url: require('./../assets/media/bday27th/1 (57).jpg')},
    {url: require('./../assets/media/bday27th/1 (58).jpg')},
    {url: require('./../assets/media/bday27th/1 (59).jpg')},
    {url: require('./../assets/media/bday27th/1 (60).jpg')},
    {url: require('./../assets/media/bday27th/1 (61).jpg')},
    {url: require('./../assets/media/bday27th/1 (62).jpg')},
    {url: require('./../assets/media/bday27th/1 (63).jpg')},
    {url: require('./../assets/media/bday27th/1 (64).jpg')},
    {url: require('./../assets/media/bday27th/1 (65).jpg')},
    {url: require('./../assets/media/bday27th/1 (66).jpg')},
    {url: require('./../assets/media/bday27th/1 (67).jpg')},
    {url: require('./../assets/media/bday27th/1 (68).jpg')},
    {url: require('./../assets/media/bday27th/1 (69).jpg')},
    {url: require('./../assets/media/bday27th/1 (70).jpg')},
    {url: require('./../assets/media/bday27th/1 (71).jpg')},
    {url: require('./../assets/media/bday27th/1 (72).jpg')},
    {url: require('./../assets/media/bday27th/1 (73).jpg')},
    {url: require('./../assets/media/bday27th/1 (74).jpg')},
    {url: require('./../assets/media/bday27th/1 (75).jpg')},
    {url: require('./../assets/media/bday27th/1 (76).jpg')},
    {url: require('./../assets/media/bday27th/1 (77).jpg')},
    {url: require('./../assets/media/bday27th/1 (78).jpg')},
    {url: require('./../assets/media/bday27th/1 (79).jpg')},
    {url: require('./../assets/media/bday27th/1 (80).jpg')},
    {url: require('./../assets/media/bday27th/1 (81).jpg')},
    {url: require('./../assets/media/bday27th/1 (82).jpg')},
    {url: require('./../assets/media/bday27th/1 (83).jpg')},
    {url: require('./../assets/media/bday27th/1 (84).jpg')},
    {url: require('./../assets/media/bday27th/1 (85).jpg')},
    {url: require('./../assets/media/bday27th/1 (86).jpg')},
    {url: require('./../assets/media/bday27th/1 (87).jpg')},
    {url: require('./../assets/media/bday27th/1 (88).jpg')},
    {url: require('./../assets/media/bday27th/1 (89).jpg')},
    {url: require('./../assets/media/bday27th/1 (90).jpg')},
    {url: require('./../assets/media/bday27th/1 (91).jpg')},
    {url: require('./../assets/media/bday27th/1 (92).jpg')},
    {url: require('./../assets/media/bday27th/1 (93).jpg')},
    {url: require('./../assets/media/bday27th/1 (94).jpg')},
    {url: require('./../assets/media/bday27th/1 (95).jpg')},
    {url: require('./../assets/media/bday27th/1 (96).jpg')},
    {url: require('./../assets/media/bday27th/1 (97).jpg')},
    {url: require('./../assets/media/bday27th/1 (98).jpg')},
    {url: require('./../assets/media/bday27th/1 (99).jpg')},
    {url: require('./../assets/media/bday27th/1 (100).jpg')},
    {url: require('./../assets/media/bday27th/1 (101).jpg')},
    {url: require('./../assets/media/bday27th/1 (102).jpg')},
    {url: require('./../assets/media/bday27th/1 (103).jpg')},
    {url: require('./../assets/media/bday27th/1 (104).jpg')},
    {url: require('./../assets/media/bday27th/1 (105).jpg')},
    {url: require('./../assets/media/bday27th/1 (106).jpg')},
    {url: require('./../assets/media/bday27th/1 (107).jpg')},
    {url: require('./../assets/media/bday27th/1 (108).jpg')},
    {url: require('./../assets/media/bday27th/1 (109).jpg')},
    {url: require('./../assets/media/bday27th/1 (110).jpg')},
    {url: require('./../assets/media/bday27th/1 (111).jpg')},
    {url: require('./../assets/media/bday27th/1 (112).jpg')},
    {url: require('./../assets/media/bday27th/1 (113).jpg')},
    {url: require('./../assets/media/bday27th/1 (114).jpg')},
    {url: require('./../assets/media/bday27th/1 (115).jpg')},
    {url: require('./../assets/media/bday27th/1 (116).jpg')},
    {url: require('./../assets/media/bday27th/1 (117).jpg')},
    {url: require('./../assets/media/bday27th/1 (118).jpg')},
    {url: require('./../assets/media/bday27th/1 (119).jpg')},
    {url: require('./../assets/media/bday27th/1 (120).jpg')},
    {url: require('./../assets/media/bday27th/1 (121).jpg')},
    {url: require('./../assets/media/bday27th/1 (122).jpg')},
    {url: require('./../assets/media/bday27th/1 (123).jpg')},
    {url: require('./../assets/media/bday27th/1 (124).jpg')},
    {url: require('./../assets/media/bday27th/1 (125).jpg')},
    {url: require('./../assets/media/bday27th/1 (126).jpg')},
    {url: require('./../assets/media/bday27th/1 (127).jpg')},
    {url: require('./../assets/media/bday27th/1 (128).jpg')},
    {url: require('./../assets/media/bday27th/1 (129).jpg')},
    {url: require('./../assets/media/bday27th/1 (130).jpg')},
    {url: require('./../assets/media/bday27th/1 (131).jpg')},
    {url: require('./../assets/media/bday27th/1 (132).jpg')},
    {url: require('./../assets/media/bday27th/1 (133).jpg')},
    {url: require('./../assets/media/bday27th/1 (134).jpg')},
    {url: require('./../assets/media/bday27th/1 (135).jpg')},
    {url: require('./../assets/media/bday27th/1 (136).jpg')},
    {url: require('./../assets/media/bday27th/1 (137).jpg')},
    {url: require('./../assets/media/bday27th/1 (138).jpg')},
    {url: require('./../assets/media/bday27th/1 (139).jpg')},
    {url: require('./../assets/media/bday27th/1 (140).jpg')},
    {url: require('./../assets/media/bday27th/1 (141).jpg')},
    {url: require('./../assets/media/bday27th/1 (142).jpg')},
]



