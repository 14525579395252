import React, {Suspense}  from'react';
import {OrbitControls, PerspectiveCamera, Stage} from "@react-three/drei";
import { Canvas } from "@react-three/fiber";
import './about.scss';
import Deck from "../../../../assets/Deck";
import Line from "./../../../../assets/images/line.png";

const About = () => {
    return (
        <>
            {/* <div className="container about-page"> */}
                <div className="about-container">
                    <div className="lefta">
                        <Canvas camera={{ position: [35, 25, 15], fov: 50 }}>
                            <PerspectiveCamera
                                makeDefault
                                position={[500, 1.9, 1.8]}
                                fov={60}
                                zoom={0.7}
                            />
                            <Suspense fallback={null}>
                                <Stage environment="studio" intensity={0.3}>
                                    <Deck />
                                    <OrbitControls enableZoom={false} autoRotate />
                                </Stage>
                            </Suspense>
                        </Canvas>
                    </div>
                    <div className="right">
                        <h1>The one and only</h1>
                        <div className="subtitle">
                            <img src={Line} alt="line"/>
                        </div>
                        <p>
                            DJ Taz is a professional multi-genre DJ with over 5 years experience in the DJ industry. Specialising in urban music
                            such as: R&B, Hip-Hop/Rap, Dancehall, Afrobeats and many more. With a passion and love for music combined with his DJ ability to become a reputable individual in the industry.
                            <br />
                            <br />
                            <strong>Highlights:</strong>

                            <br />
                            - MADE Festival 2022
                            <br />
                            - Selfridges
                            <br />
                            - Footasylum
                            <br />
                            - Artists Features (Stylo G, MIST, Not3s & Hardy Caprio)
                            <br />
                            - DJ Features (Charlie Sloth, Seani B & Tim Westwood)
                        </p>

                        <a href="https://linktr.ee/djtazuk_?utm_source=linktree_profile_share&ltsid=5902d960-e674-4866-aeca-4af93fc49d53" target="_blank" rel="noreferrer">
                            Browse My Content
                        </a>
                    </div>
                </div>
            {/* </div> */}
        </>
    )
}

export default About